/**
 * Change the 'data-ui-hitbox' attribute to `newState` on all descentands of `el`
 *
 * @param {Element} el the parent element
 * @param {Boolean | String} newState the new value of 'data-ui-hitbox' attribute
 */
function changeUiHitboxes(el, newState) {
    const hitboxes = el.querySelectorAll("[data-ui-hitbox]");

    for (const hitbox of hitboxes) {
        hitbox.setAttribute("data-ui-hitbox", newState);
    }
}

/**
 * Enables ui hitboxes on all descendands of `el`
 *
 * @param {Element} el the parent element
 */
function enableUiHitboxes(el) {
    changeUiHitboxes(el, true);
}

/**
 * Disables ui hitboxes on all descendands of `el`
 *
 * @param {Element} el
 */
function disableUiHitboxes(el) {
    changeUiHitboxes(el, false);
}
